import React from "react"
import Layout from "../components/layout"

const NewsletterSuccess = () => (
    <Layout>
        <div>
            <h2><strong>Thank you for subscribing to the newsletter!</strong></h2>
            <h3>Check you email for confirmation link.</h3>
        </div>
    </Layout>
)

export default NewsletterSuccess